module controllers {
    export module forex {

        interface IForexCreateScope extends ng.IScope {
        }

        interface IForexCreateParams extends ng.ui.IStateParamsService {
        }

        export class forexCreateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'documentRepositoryService',
                'lookupService',
                "supplierService",
                "localeService",
                'forexService',
                "customerService",
                "paymentMethodService",
                "counterService",
                'currencyService',
                'bankService'
            ];

            breadCrumbDesc: string;
            selectedTab: number = 0;

            disableForexTab: boolean = false;
            disableAllocationTab: boolean = false;

            fecId: number = 0;

            ownerEntityId: number = 0;
            entityList: Array<interfaces.applicationcore.IEntity>;

            loadPromises: ng.IPromise<any>[];

            forexCounterId: number = 0;
            forexCounterList: Array<interfaces.applicationcore.IDropdownModel>;
            isCustomForexNumber: boolean = false;
            forexCounterNumber: string = "";

            createForexDefault : interfaces.forex.IForexCreateDefaults;

            typeModel: interfaces.applicationcore.IDropdownModel;
            forexContractTypeModel : interfaces.applicationcore.IDropdownModel;
            bankModel : interfaces.applicationcore.IDropdownModel;
            maturityTypeModel: interfaces.applicationcore.IDropdownModel;
            
            ContractAmount: number = 0;
            AmountAllocated: number = 0;
            AmountUnallocated: number = 0;

            currencyModel:  interfaces.applicationcore.IDropdownModel;
            maturityStartDate: moment.Moment;
            maturityEndDate: moment.Moment;

            maturityTypeModelList:  interfaces.applicationcore.IDropdownModel[] = [];

            maturityStartDateOpen: boolean = false;
            maturityEndDateOpen: boolean = false;
            
            disableAllocations: boolean = false;

            IsLoading: boolean = false;

            ShowAllocationsGrid: boolean = false;

            AllocationList: interfaces.forex.IForexAllocation[] = [];
            selectedAllocation : uiGrid.IGridRow;
            selectedAllocationEntity: interfaces.forex.IForexAllocation;
            selectedRowIndex: number = 0;

            apiAllocationList: uiGrid.IGridApi;

            DisableNextButton: boolean = false;


            constructor(
                private $scope: IForexCreateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IForexCreateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                private supplierService: interfaces.master.ISupplierService,
                private localeService: interfaces.applicationcore.ILocaleService,
                private forexService: interfaces.forex.IForexService,
                private customerService: interfaces.master.ICustomerService,
                public paymentMethodService: services.master.paymentMethodService,
                private counterService: interfaces.master.ICounterService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                public bankService: interfaces.master.IBankService,
            ) {

                this.breadCrumbDesc = "Create Forex Contract";

                //Get Entities Data
                this.loadPromises = [];

                //We need to load the Entities and the Current Entity First
                //Current Entity can be either the active Entity the user is working on  --> New Payment
                //OR Current Entity will be the OwnerEntity of the Payment --> Existing Payment

                this.loadPromises.push(this.loadEntities());
                this.loadPromises.push(this.getCurrentEntity());


                bsLoadingOverlayService.wrap({
                    referenceId: 'create.payment'
                }, () => {
                    return $q.all(this.loadPromises).then((data) => {
                        //Now we can load the rest of the Controls, after determining which is the CurrentEntity

                        this.loadControls();
                    });
                });


            }

            GetEnabled(){


                var valid : boolean = !!(this.ownerEntityId && this.typeModel && this.currencyModel && this.bankModel  && this.ContractAmount)


                if (!this.typeModel)
                {
                    valid = false;
                    return valid;
                }
                


                if (this.typeModel.Id === 466 || this.typeModel.Id === 467)
                    valid = valid && !!this.forexContractTypeModel && !!this.maturityTypeModel

                if (this.isCustomForexNumber && this.forexCounterNumber === "")
                        valid = false;

                if (!this.isCustomForexNumber && this.forexCounterId===0)
                        valid = false;
                 
                return valid;                
            }

            loadControls() {

                this.loadPromises = [];


                this.loadPromises.push(this.loadForexCounter());
                this.loadPromises.push(this.loadMaturityTypes());


                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.payment'
                }, () => {
                    return this.$q.all(this.loadPromises).then((data) => {
                        //Now we can load the defaults AFTER all the dropdowns have been loaded


                    });
                });

            }

            loadForexCounter() {

                let deferred = this.$q.defer<boolean>();

                if (this.ownerEntityId) {

                    this.counterService.getDropdownList().query({
                        ownerEntityId: this.ownerEntityId,
                        counterTypeId: Enum.EnumCounterTypes.ForexContract,
                    }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                        this.forexCounterList = result;

                        angular.forEach(result, (o) => {
                            if (o.Selected) {
                                this.forexCounterId = o.Id;
                            }
                        });

                        deferred.resolve(true);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        deferred.resolve(true);
                    })
                } else {
                    deferred.resolve(true);
                }

                return deferred.promise;
            }

            forexTabSelected() {

            }

            allocationTabSelected(){
                this.ShowAllocationsGrid=true;

                if (this.typeModel.Id === 466)
                {
                    this.gvwAllocations.columnDefs[4].visible = true;
                    this.gvwAllocations.columnDefs[5].visible = false;
                }
                else if (this.typeModel.Id === 467)
                {
                    this.gvwAllocations.columnDefs[4].visible = false;
                    this.gvwAllocations.columnDefs[5].visible = true;
                }
                else
                {
                    this.gvwAllocations.columnDefs[3].visible = false;
                    this.gvwAllocations.columnDefs[4].visible = false;
                    this.gvwAllocations.columnDefs[5].visible = false;
                }

                this.recalculateBalances();

            }

            ownerEntityChanged() {

            }

            getCreateForexDefaults(){

                return this.forexService.GetCreateForexDefaults(this.ownerEntityId).get((data: interfaces.forex.IForexCreateDefaults) => {
                    this.createForexDefault = data;

                    if (this.typeModel.Id === 466)
                    {
                        this.forexContractTypeModel = this.createForexDefault.ImportTransactionType?  this.createForexDefault.ImportTransactionType : undefined;
                    }

                    if (this.typeModel.Id === 467)
                    {
                        this.forexContractTypeModel = this.createForexDefault.ExportTransactionType?  this.createForexDefault.ExportTransactionType : undefined;
                    }            
                    
                    this.contractTypeChanged(this.forexContractTypeModel);

                    this.currencyModel = this.createForexDefault.Currency ? this.createForexDefault.Currency : undefined;
                    this.bankModel = this.createForexDefault.Bank? this.createForexDefault.Bank : undefined;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise
            }

            loadContractTypes(){
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ForexContractType
                }, (resultList) => {
                       
                }).$promise;
            }

            loadMaturityTypes(){
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ForexContactMaturity
                }, (resultList) => {
                        this.maturityTypeModelList = resultList;
                }).$promise;
            }


            loadBanks() {
                return this.bankService.getDropdownList(this.ownerEntityId, "").query({
                }, (resultList) => {
                }).$promise;
            }


            loadTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.FECType
                }, (resultList) => {
                       
                }).$promise;
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            customForexCounterChanged(){
                this.forexCounterNumber = '';
                if (this.isCustomForexNumber)
                {
                    this.forexCounterId = 0;                    
                }
                else
                {

                }
            }


            typeChanged(typeModel: interfaces.applicationcore.IDropdownModel)
            {
               this.typeModel = typeModel;
               if (this.typeModel.Id === 613){
                    this.forexContractTypeModel = null;   
                    this.maturityTypeModel = null;                 
                }

               this.bsLoadingOverlayService.wrap({
                   referenceId: 'create.forex'
               }, () => {
                   return this.getCreateForexDefaults();
               });
            }

            contractTypeChanged(forexContractTypeModel: interfaces.applicationcore.IDropdownModel){
                if (forexContractTypeModel){
                    this.forexContractTypeModel = forexContractTypeModel;

                    if (this.forexContractTypeModel.Id == 415)
                    {
                        //Maturity Type = Date = 418
                        var maturity = this.maturityTypeModelList.filter(x => x.Id == 418)[0];
                        this.maturityTypeModel = maturity;
                    }

                    if (this.forexContractTypeModel.Id == 416)
                    {
                        //Maturity Type = Any Date Between = 419
                        var maturity = this.maturityTypeModelList.filter(x => x.Id == 419)[0];
                        this.maturityTypeModel = maturity;
                    }
                    
                    if (this.forexContractTypeModel.Id == 417)
                    {
                        //Maturity Type = At Any Date Up To = 420
                        var maturity = this.maturityTypeModelList.filter(x => x.Id == 420)[0];
                        this.maturityTypeModel = maturity;
                    }                

                }
            }

            loadEntities() {

                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                //default the CurrentEntity and all other controls from there

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;

                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            close(){
                this.$state.go("^");
            }

            submitForex(){
                this.disableAllocations = true;
                this.IsLoading = true;

                var forexAllocationList : interfaces.forex.IForexAllocation[] = [];

                var forexAllocationLines : interfaces.forex.IForexAllocationLines = {
                    forexAllocationList :[]
                };

                forexAllocationLines.forexAllocationList = this.AllocationList;

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.payment'
                }, () => {
                    return this.CreateForex(forexAllocationLines);
                });


            }

            CreateForex(forexAllocationLines : interfaces.forex.IForexAllocationLines){

                var forexCreateParamas : interfaces.forex.IForexCreateParams = {
                    ownerEntityId : this.ownerEntityId,
                    typeId : this.typeModel.Id,
                    currencyId : this.currencyModel.Id,
                    forexContractTypeId : this.forexContractTypeModel? this.forexContractTypeModel.Id: 614,
                    bankId : this.bankModel.Id,
                    maturityTypeId: this.maturityTypeModel? this.maturityTypeModel.Id : null,
                    Amount : this.ContractAmount,
                    forexCounterId :   this.forexCounterId ? this.forexCounterId : 0,
                    customForexNumber:  this.forexCounterNumber ? this.forexCounterNumber : "",
                    maturityStartDate :   this.maturityStartDate,
                    maturityEndDate: this.maturityEndDate,
                    forexAllocationList: forexAllocationLines.forexAllocationList
                };

                return this.forexService.createForexContract().save(forexCreateParamas, (result) => {

                    this.generalService.displayMessageHandler(result);

                    this.IsLoading = false;

                    if (!result.HasErrorMessage) {                     
                            this.$state.go("auth.Forex.Update", { fecId: Number(result.ID) });
                    }

                }, (reponseError) => {
                    this.generalService.displayMessageHandler(reponseError.data);

                    this.IsLoading = false;

                }).$promise;
            }


            registerGridApi(gridApi: uiGrid.IGridApi) {
                
                this.apiAllocationList = gridApi;
                this.apiAllocationList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selectedAllocation = selected;

                    this.DisableNextButton = false;

                    if (this.apiAllocationList.selection.getSelectedRows().length === 0) {
                        this.DisableNextButton = true;
                    }
                    else
                    {
                        this.DisableNextButton = false;
                    }
                });

                this.$timeout(() => {
                    if (this.selectedAllocation)
                        this.apiAllocationList.selection.selectRow(this.selectedAllocation.entity);
                });
            }


            gvwAllocations: uiGrid.IGridOptions = {
                data: this.AllocationList,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: true,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.forexCreateCtrl.editForexAllocation(row.entity)"  class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: true
                },
                {
                    name: 'Entity',
                    displayName: 'Entity',
                    field: 'Entity',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Display}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 100
                },
                {
                    name: 'Company',
                    displayName: 'Company',
                    field: 'Company',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Display}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 300
                },                 
                {
                    name: 'PurchaseOrder',
                    displayName: 'Purchase Order',
                    field: 'PurchaseOrder',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Code}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 140,
                    visible: true
                },            
                {
                    name: 'SalesOrder',
                    displayName: 'Sales Order',
                    field: 'SalesOrder',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Code}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 140,
                    visible:  true
                },
                {
                    name: "Amount",
                    displayName: "Amount",
                    field: "Amount",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }
                ,
                {
                    name: "Balance",
                    displayName: "Balance",
                    field: "Balance",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: 'Currency',
                    displayName: 'Currency',
                    field: 'Currency',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Code}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 100
                },]
            };

            editForexAllocation(forexAllocation: interfaces.forex.IForexAllocation)
            {
                
                this.selectedAllocationEntity = forexAllocation;

                this.AddAllocation();
            }

            recalculateBalances(){
                this.AmountAllocated = this.AllocationList
                .reduce((sum, current) => sum + current.Amount, 0);
                this.AmountUnallocated = this.ContractAmount - this.AmountAllocated;
            }

            AddAllocation(){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `<div class="modal-header"><h3 class="modal-title" id="modal-title">Add Allocation</h3></div>
                                <div class="modal-body"> 
                                <div class="gts-padding">
                                <fieldset>
                                    <div class="row vertical-align">
                                        <div class="col-md-3">
                                            <req></req>
                                            <label>Owner Entity</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="OwnerEntityId"  ng-change="addNewCtrl.ownerEntityChanged()"
                                                ng-model="addNewCtrl.ownerEntityModel"
                                                load-data="addNewCtrl.loadEntities(searchText)" pagination-search="true"
                                                refresh-delay="1000" required="false">
                                            </gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align" ng-if="addNewCtrl.typeId === 467">
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Customer</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <gts-dropdown name="customer" ng-model="addNewCtrl.customerModel"   load-data="addNewCtrl.loadCustomers(searchText)" required="true"  pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                                        </div>
                                    </div>
                                    <div class="row vertical-align" ng-if="addNewCtrl.typeId === 467">
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Sales Order</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <gts-dropdown name="salesOrder" ng-model="addNewCtrl.salesOrderModel"   load-data="addNewCtrl.loadSalesOrders(searchText)" required="true"  pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                                        </div>
                                    </div>                                    
                                    <div class="row vertical-align" ng-if="addNewCtrl.typeId === 466">
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Supplier</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <gts-dropdown name="customer" ng-model="addNewCtrl.supplierModel"   load-data="addNewCtrl.loadSuppliers(searchText)" required="true"  pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                                        </div>
                                    </div>    
                                    <div class="row vertical-align" ng-if="addNewCtrl.typeId === 466">
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Purchase Order</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <gts-dropdown name="purchaseOrder" ng-model="addNewCtrl.purchaseOrderModel"   load-data="addNewCtrl.loadPurchaseOrders(searchText)" required="true"  pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                                        </div>
                                    </div>
                                    <div class="row vertical-align"  >
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Amount</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                        <p class="input-group input-group-sm has-feedback"
                                                            ng-class="{'has-error': addNewCtrl.Amount.$invalid}">
                                                            <input type="number" ignore-wheel name="Amount" ng-change="addNewCtrl.AmountChanged()"
                                                                ng-model="addNewCtrl.Amount" class="form-control" step="0.01" />
                                                            <span class="input-group-addon">{{addNewCtrl.currencyModel.Code}}</span>
                                                        </p>                                                            
                                                        </div>
                                                        <div class="col-md-3">
                                                        <p class="input-group input-group-sm has-feedback">
                                                            <input type="number" ignore-wheel name="Percentage" ng-change="addNewCtrl.PercentageChanged()"
                                                                ng-model="addNewCtrl.Percentage" class="form-control" step="0.01" />
                                                            <span class="input-group-addon">%</span>
                                                        </p>                                                            
                                                        </div>                                                        
                                    </div>
                                    <div class="row vertical-align" >
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Value Surrendered</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                        <p class="input-group input-group-sm has-feedback"
                                                            ng-class="{'has-error': addNewCtrl.Surrendered.$invalid}">
                                                            <input type="number" ignore-wheel name="Surrendered" ng-disabled="true"
                                                                ng-model="addNewCtrl.Surrendered" class="form-control" step="0.01" />
                                                            <span class="input-group-addon">{{addNewCtrl.currencyModel.Code}}</span>
                                                        </p>                                                            
                                                        </div>
                                                        <div class="col-md-3">
                                                            of {{addNewCtrl.ContractAmount | number}}&nbsp;{{addNewCtrl.currencyModel.Code}}                                                     
                                                        </div>                                                          
                                    </div>                                                                                                                                                     
                                </fieldset>
                                </div>
                                </div>
                                <div class="modal-footer">                               
                                    <button class="btn btn-primary" type="button"  ng-disabled="!addNewCtrl.GetEnabled()" ng-click="addNewCtrl.add()">{{addNewCtrl.SaveBtnText}}</button>
                                    <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                                </div>
                               
                    `,
                    controller: class addNewCtrl {
                        
                        forexAllocation: interfaces.forex.IForexAllocation;
                        ownerEntityModel: interfaces.applicationcore.IDropdownModel;
                        supplierModel : interfaces.applicationcore.IDropdownModel;
                        customerModel :  interfaces.applicationcore.IDropdownModel;
                        salesOrderModel :  interfaces.applicationcore.IDropdownModel;
                        purchaseOrderModel :  interfaces.applicationcore.IDropdownModel;
                        entityList: interfaces.applicationcore.IDropdownModel[];
                        Amount: number = 0;
                        Surrendered: number = 0;
                        Percentage: number = 0;
                        SaveBtnText : string = "Add"

                        loadPromises: ng.IPromise<any>[];


                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private refundSettingService: interfaces.master.IRefundSettingService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                            private supplierService: interfaces.master.ISupplierService,
                            private customerService: interfaces.master.ICustomerService,
                            private $q: ng.IQService,
                            private forexService: interfaces.forex.IForexService,
                            private forexAllocationUpdate: interfaces.forex.IForexAllocation,
                            private typeId : number,
                            private currencyModel:  interfaces.applicationcore.IDropdownModel,
                            private entityId:  number,
                            private ContractAmount: number
                        ) {
                            if (this.forexAllocationUpdate){
                                this.SaveBtnText = "Save";
                            }
                            this.loadControls();
                        }

                        GetEnabled(){
                            if (this.typeId === 466) //Purchase
                                return this.ownerEntityModel && this.supplierModel && this.purchaseOrderModel && this.Amount;
                            else if (this.typeId === 467)
                                return this.ownerEntityModel && this.customerModel && this.salesOrderModel && this.Amount;
                            else
                            return this.ownerEntityModel  && this.Amount;
                        }

                        loadEntities(searchText: string) {
                            return this.entityService.getDropdownList(searchText).query(
                                (data:  interfaces.applicationcore.IDropdownModel[]) => {
                                    this.entityList = data;
                                }, (failureData) => {
                                }).$promise;
                        }

                        ownerEntityChanged(){
                            this.supplierModel = null;
                            this.customerModel = null;
                            this.salesOrderModel = null;
                            this.purchaseOrderModel  = null;
                        }

                        AmountChanged(){
                            this.Percentage = (this.Amount / this.ContractAmount) * 100
                        }

                        PercentageChanged(){
                            if (this.Percentage)
                            {
                                this.Amount = (this.Percentage/100) * this.ContractAmount;
                            }
                        }

                        loadControls() {
                            this.loadPromises = [];                    
                            this.loadPromises.push(this.loadEntities(''));                   
                            return this.$q.all(this.loadPromises).then((data) => {
                                    //Now we can load the defaults AFTER all the dropdowns have been loaded
                                    this.ownerEntityModel =  this.entityList.filter(x => x.Id == this.entityId)[0];

                                    if (this.forexAllocationUpdate)
                                    {
                                        this.ownerEntityModel = this.forexAllocationUpdate.Entity;

                                        if (this.typeId === 466)
                                        {
                                            this.supplierModel = this.forexAllocationUpdate.Company;
                                            this.purchaseOrderModel = this.forexAllocationUpdate.PurchaseOrder;
                                        }
                                        else
                                        {
                                            this.customerModel = this.forexAllocationUpdate.Company;
                                            this.salesOrderModel = this.forexAllocationUpdate.SalesOrder;
                                        }

                                        this.Amount = this.forexAllocationUpdate.Amount; 
                                        this.AmountChanged();                                       
                                    }
                            });            
                        }

                        loadPurchaseOrders(searchText: string) {
                            return this.forexService.getPODropdownList().query({
                                ownerEntityId: this.ownerEntityModel.Id,
                                supplierId: this.supplierModel.Id,
                                currencyId: this.currencyModel.Id,
                                searchText: searchText
                            }, () => {
            
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                        }

                        loadSalesOrders(searchText: string) {
                            return this.forexService.getSODropdownList().query({
                                ownerEntityId: this.ownerEntityModel.Id,
                                customerId: this.customerModel.Id,
                                currencyId: this.currencyModel.Id,
                                searchText: searchText
                            }, () => {
            
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                        }                        

                        //Load Suppliers
                        loadSuppliers(searchText: string)
                        {
                            if (this.ownerEntityModel) {
                                return this.supplierService.getSupplierDropdown(this.ownerEntityModel.Id, searchText).query(() => {
            
                                }, (failureData) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                }).$promise;
                            } else {
                                var defer = this.$q.defer();
                                defer.resolve([]);
                                return defer.promise;
                            }
                        }
            
                        //Load Customers
                        loadCustomers(searchText: string) {
                            if (this.ownerEntityModel) {
                                return this.customerService.getDropdownList(this.ownerEntityModel.Id, searchText).query(() => {
            
                                }, (failureData) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                }).$promise;
                            } else {
                                var defer = this.$q.defer();
                                defer.resolve([]);
                                return defer.promise;
                            }
                        }
            

                        add() { 
                            this.forexAllocation = {   Id: 0,
                                                        index: this.forexAllocationUpdate? this.forexAllocationUpdate.index:0,
                                                        Entity: this.ownerEntityModel, 
                                                        Company: this.customerModel?this.customerModel : this.supplierModel,
                                                        SalesOrder: this.salesOrderModel,
                                                        PurchaseOrder: this.purchaseOrderModel,
                                                        Amount: this.Amount,
                                                        Balance: this.Amount,
                                                        SurrenderedAmount: 0,
                                                        Currency: this.currencyModel
                                                    }
                            this.$uibModalInstance.close(this.forexAllocation);
                        }

                        cancel() {
                            this.$uibModalInstance.close(null);
                        }
                    },
                    controllerAs: "addNewCtrl",
                    resolve: { 
                        forexAllocationUpdate :  this.selectedAllocationEntity? this.selectedAllocationEntity: null,
                        typeId: this.typeModel? this.typeModel.Id : null,
                        currencyModel: this.currencyModel,
                        entityId:    this.ownerEntityId,
                        ContractAmount: this.ContractAmount
                    }
                }).result.then((result  : interfaces.forex.IForexAllocation) => {
                   
                    if (result) {
                        
                        if (this.selectedAllocation){
                            this.selectedAllocation.entity = result;  
                            var alloc = this.AllocationList.filter(x => x.index == result.index)[0];
                            
                            alloc.Amount = result.Amount;
                            alloc.Balance = result.Balance;
                            alloc.Entity = result.Entity;
                            alloc.Company = result.Company;
                            alloc.SalesOrder = result.SalesOrder;
                            alloc.PurchaseOrder = result.PurchaseOrder;
                        }
                        else 
                        {
                            result.index = this.AllocationList.length;
                            this.AllocationList.push(result); 
                        }


 
                        this.$timeout(() => {
                           
                        });                      
                    }

                    
                   this.selectedAllocation = null;
                    this.selectedAllocationEntity = null;
                    this.apiAllocationList.selection.clearSelectedRows();

                   

                    this.$timeout(() => {
                        if (this.apiAllocationList) {
                            this.recalculateBalances();        
                        }
                    });    

                   

                });
            }
        }

        angular.module("app").controller("forexCreateCtrl", controllers.forex.forexCreateCtrl);
    }
}